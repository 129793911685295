import React from 'react';
import PrivacyPolicyTopicDetail from './PrivacyPolicyTopicDetail';

const PrivacyPolicy = () => {
  return (
    <section className='h-full my-16'>
      <div className='flex flex-col lg:flex-row'>
        <div className='container mx-auto max-w-full 2xl:max-w-full'>
          <div className="mx-auto max-w-lg text-center">
            <h1 className="text-2xl font-bold sm:text-3xl text-gray-700">POLÍTICA DE PRIVACIDADE</h1>
          </div>

          <p className="mt-4 text-gray-500">
            Este site é mantido e operado por BBS CONSTRUTORA E IMOBILIARIA LTDA.
          </p>

          <p className="mt-4 text-gray-500">
            Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso site.
            Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos às disposições da
            Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).
          </p>

          <p className="mt-4 text-gray-500">
            Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de privacidade,
            que contém informações importantes sobre:
          </p>
          <ul className="block gap-1 mt-3 ml-6 list-disc text-gray-500">
            <li>Quem deve utilizar nosso site</li>
            <li>Quais dados coletamos e o que fazemos com eles;</li>
            <li>Seus direitos em relação aos seus dados pessoais; e</li>
            <li>Como entrar em contato conosco.</li>
          </ul>

          <div>
            <div className="mt-4 space-y-4">
              <PrivacyPolicyTopicDetail title={'1. Dados que coletamos e motivos da coleta'}>
                <>
                  <p className="mt-4 text-gray-500">
                    Nosso site coleta e utiliza alguns dados pessoais de nossos usuários, de acordo com o disposto nesta seção.
                  </p>
                  <p className="mt-4 text-gray-600 font-medium">
                    1. Dados pessoais fornecidos expressamente pelo usuário
                  </p>
                  <p className="mt-4 text-gray-500">
                    Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao utilizar nosso site:
                  </p>
                  <ul className="block gap-1 mt-3 ml-6 list-disc text-gray-500">
                    <li>Nome</li>
                    <li>Endereço</li>
                    <li>E-mail</li>
                    <li>Número de telefone</li>
                    <li>Mensagem enviada por meio de formulário</li>
                  </ul>
                  <p className="mt-4 text-gray-500">
                    A coleta destes dados ocorre nos seguintes momentos:
                  </p>
                  <ul className="block gap-1 mt-3 ml-6 list-disc text-gray-500">
                    <li>Quando o usuário utiliza o formulário de contato;</li>
                  </ul>
                  <p className="mt-4 text-gray-500">
                    Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:
                  </p>
                  <ul className="block gap-1 mt-3 ml-6 list-disc text-gray-500">
                    <li>Para que o usuário possam adquirir nosso produtos e serviços;</li>
                    <li>Para que nos possamos enviar nossas ofertas de produto e serviços aos usuários.</li>
                  </ul>

                  <p className="mt-4 text-gray-600 font-medium">
                    2. Dados sensíveis
                  </p>
                  <p className="mt-4 text-gray-500">
                    Não serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos
                    nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, não haverá coleta de dados
                    sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a
                    organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual,
                    dado genético ou biométrico, quando vinculado a uma pessoa natural.
                  </p>

                  <p className="mt-4 text-gray-600 font-medium">
                    2. Cookies
                  </p>
                  <p className="mt-4 text-gray-500">
                    Cookies são pequenos arquivos de texto baixados automaticamente em seu dispositivo quando você
                    acessa e navega por um site. Eles servem, basicamente, para seja possível identificar dispositivos,
                    atividades e preferências de usuários.
                  </p>
                  <p className="mt-4 text-gray-500">
                    Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário,
                    não sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não tenham
                    partido do usuário ou da forma como utiliza os recursos do site.
                  </p>

                  <ul className="block gap-1 mt-3 ml-6 list-none text-gray-500">
                    <li>a. Cookies do site</li>
                  </ul>
                  <p className="mt-4 text-gray-500">
                    Os cookies do site são aqueles enviados ao computador ou dispositivo do usuário e administrador exclusivamente pelo site.
                  </p>
                  <p className="mt-4 text-gray-500">
                    As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência
                    do usuário, sendo que alguns cookies podem, por exemplo, ser utilizados para lembrar as preferências e escolhas
                    do usuário, bem como para o oferecimento de conteúdo personalizado.
                  </p>

                  <ul className="block gap-1 mt-3 ml-6 list-none text-gray-500">
                    <li>b. Gestão de cookies</li>
                  </ul>
                  <p className="mt-4 text-gray-500">
                    O usuário poderá se opor ao registro de cookies pelo site, bastando que desative esta opção no seu próprio navegador.
                    Mais informações sobre como fazer isso em alguns dos principais navegadores utilizados hoje podem ser acessadas a
                    partir dos seguintes links:
                  </p>
                  <ul className="block gap-1 mt-3 ml-6 list-none text-gray-500">
                    <li><span className='font-bold'>Internet Explorer:</span> https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete- manage-cookies</li>
                    <li><span className='font-bold'>Safari:</span> https://support.apple.com/pt-br/guide/safari/sfri11471/mac</li>
                    <li><span className='font-bold'>Google Chrome:</span> https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt</li>
                    <li><span className='font-bold'>Firefox:</span> https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam</li>
                    <li><span className='font-bold'>Opera:</span> https://www.opera.com/help/tutorials/security/privacy/</li>
                  </ul>
                  <p className="mt-4 text-gray-500">
                    A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do site,
                    comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das preferências do usuário
                    que eventualmente tiverem sido salvas, prejudicando sua experiência.
                  </p>

                  <p className="mt-4 text-gray-600 font-medium">
                    4. Coleta de dados não previstos expressamente
                  </p>
                  <p className="mt-4 text-gray-500">
                    Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados,
                    desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento em outra
                    base legal prevista em lei.
                  </p>
                  <p className="mt-4 text-gray-500">
                    Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão informadas aos usuários do site.
                  </p>

                </>
              </PrivacyPolicyTopicDetail>

              <PrivacyPolicyTopicDetail title={'2. Compartilhamento de dados pessoais com terceiros'}>
                <>
                  <p className="mt-4 text-gray-500">
                    Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é possível que o façamos para cumprir alguma
                    determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade pública.
                  </p>
                </>
              </PrivacyPolicyTopicDetail>

              <PrivacyPolicyTopicDetail title={'3. Por quanto tempo seus dados pessoais serão armazenados'}>
                <>
                  <p className="mt-4 text-gray-500">
                    Os dados pessoais coletados pelo site são armazenados e utilizados por período de tempo que corresponda ao necessário
                    para atingir as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do
                    controlador do site e as disposições legais ou regulatórias aplicáveis.
                  </p>
                  <p className="mt-4 text-gray-500">
                    Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de nossas bases de dados ou anonimizados,
                    salvo nos casos em que houver a possibilidade ou a necessidade de armazenamento em virtude de disposição legal ou regulatória.
                  </p>
                </>
              </PrivacyPolicyTopicDetail>

              <PrivacyPolicyTopicDetail title={'4. Bases legais para o tratamento de dados pessoais'}>
                <>
                  <p className="mt-4 text-gray-500">
                    Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja, uma base legal, que nada mais é que
                    uma justificativa que a autorize, prevista na Lei Geral de Proteção de Dados Pessoais.
                  </p>
                  <p className="mt-4 text-gray-500">
                    Todas as Nossas atividades de tratamento de dados pessoais possuem uma base legal que as fundamenta, dentre as permitidas pela
                    legislação. Mais informações sobre as bases legais que utilizamos para operações de tratamento de dados pessoais específicas
                    podem ser obtidas a partir de nossos canais de contato, informados ao final desta Política.
                  </p>
                </>
              </PrivacyPolicyTopicDetail>

              <PrivacyPolicyTopicDetail title={'5. Direitos do usuário'}>
                <>
                  <p className="mt-4 text-gray-500">
                    O usuário do site possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais:
                  </p>

                  <ul className="block gap-1 mt-3 ml-6 list-disc text-gray-500">
                    <li>confirmação da existência de tratamento;</li>
                    <li>acesso aos dados;</li>
                    <li>correção de dados incompletos, inexatos ou desatualizados;</li>
                    <li>anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;</li>
                    <li>portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
                    <li>eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos previstos em lei;</li>
                    <li>informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
                    <li>informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
                    <li>revogação do consentimento.</li>
                  </ul>

                  <p className="mt-4 text-gray-500">
                    É importante destacar que, nos termos da LGPD, não existe um direito de eliminação de dados tratados com fundamento em
                    bases legais distintas do consentimento, a menos que os dados seja desnecessários, excessivos ou tratados em desconformidade
                    com o previsto na lei.
                  </p>
                  <p className="mt-4 text-gray-600 font-medium">
                    1. Como o titular pode exercer seus direitos
                  </p>
                  <p className="mt-4 text-gray-500">
                    Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição,
                    poderemos solicitar documentos ou outras informações que possam auxiliar em sua correta identificação, a fim de resguardar
                    nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário, e o requerente
                    receberá todas as informações relacionadas.
                  </p>
                </>
              </PrivacyPolicyTopicDetail>

              <PrivacyPolicyTopicDetail title={'6. Medidas de segurança no tratamento de dados pessoais'}>
                <>
                  <p className="mt-4 text-gray-500">
                    Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações
                    de destruição, perda, extravio ou alteração desses dados.
                  </p>

                  <p className="mt-4 text-gray-500">
                    As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que
                    uma eventual violação geraria para os direitos e liberdades do usuário, e os padrões atualmente empregados no mercado por
                    empresas semelhantes à nossa.
                  </p>
                  <p className="mt-4 text-gray-500">
                    Entre as medidas de segurança adotadas por nós, destacamos as seguintes:
                  </p>

                  <ul className="block gap-1 mt-3 ml-6 list-disc text-gray-500">
                    <li>Restrição de acessos a banco de dados;</li>
                    <li>Controle das informações.</li>
                  </ul>

                  <p className="mt-4 text-gray-500">
                    Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que ocorra algum problema
                    motivado exclusivamente por um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa
                    exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos,
                    em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação
                    excepcional como essas, sobre as quais não temos nenhum tipo de controle.
                  </p>

                  <p className="mt-4 text-gray-500">
                    De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para
                    qualquer de nossos usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido,
                    em conformidade com o disposto na Lei Geral de Proteção de Dados.
                  </p>
                </>
              </PrivacyPolicyTopicDetail>

              <PrivacyPolicyTopicDetail title={'7. Reclamação a uma autoridade de controle'}>
                <>
                  <p className="mt-4 text-gray-500">
                    Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares de dados pessoais que se sentirem,
                    de qualquer forma, lesados, podem apresentar reclamação à Autoridade Nacional de Proteção de Dados.
                  </p>
                </>
              </PrivacyPolicyTopicDetail>

              <PrivacyPolicyTopicDetail title={'8. Alterações nesta política'}>
                <>
                  <p className="mt-4 text-gray-500">
                    A presente versão desta Política de Privacidade foi atualizada pela última vez em: 27/04/2023.
                  </p>

                  <p className="mt-4 text-gray-500">
                    Reservamo-nos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais
                    alterações feitas em nosso site, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação
                    daquelas já existentes.
                  </p>

                  <p className="mt-4 text-gray-500">
                    Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.
                  </p>
                </>
              </PrivacyPolicyTopicDetail>

              <PrivacyPolicyTopicDetail title={'9. Como entrar em contato conosco'}>
                <>
                  <p className="mt-4 text-gray-500">
                    Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato
                    com nosso Encarregado de Proteção de Dados Pessoais, por algum dos canais mencionados abaixo:
                  </p>
                  <ul className="block gap-1 mt-3 ml-6 list-none text-gray-500">
                    <li>E-mail: suporte@bbsimoveis.com.br</li>
                    <li>Telefone: (12) 3354-2663</li>
                    <li>Endereço postal: Avenida Edouard Six, 150 Jd. Paraíba - Jacareí-SP</li>
                  </ul>
                </>
              </PrivacyPolicyTopicDetail>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyPolicy;
