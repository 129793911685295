import React from 'react';

import { BiArea, BiBath, BiBed, BiCar } from 'react-icons/bi';
import { formatCurrency } from '../utils/formatCurrency';
import ImageSlideshow from './ImageSlideshow';

import NoImageImg from '../assets/img/no-image.png';
import PropertyFeatures from './PropertyFeatures';

const House = ({ house }) => {
  return (
    <div className='bg-white shadow-lg rounded-[15px] w-full h-full max-w-full mx-auto cursor-pointer hover:shadow-2xl transition overflow-hidden border-solid border-[1px] border-zinc-100'>
      <span className='z-[2] absolute mt-4 inline-block bg-orange-500 opacity-80 rounded-r-full shadow-widget px-3 text-sm font-normal text-white mr-2 mb-2'>
        {house.options.length > 0 ? (
          <>
            {`${house.option} / ${house.options.join(' / ')}`}
          </>
        ) : (
          <>
            {house.option}
          </>
        )}
      </span>

      {/* Removido a sombra das imagens */}
      {/* <div className='shadow-md shadow-gray-500/50'> */}
      <div>
        {house.images.length > 0 ? (
          // <img className='w-full h-52 object-cover' src={house.images[0]} alt={house.id} />
          <ImageSlideshow
            images={house.images}
            mode='listing'
          />
        ) : (
          <img className='w-full h-52 object-cover' src={NoImageImg} alt='Foto não disponível' />
        )}
      </div>

      <div className='px-6 py-4'>
        <span className='inline-block bg-green-2 rounded-full shadow-md px-3 text-sm text-white mr-2 mb-2'>{house.property}</span>
        <span className='inline-block bg-blue-500 rounded-full shadow-md px-3 text-sm text-white mr-2 mb-2'>{house.city}</span>
        <div className='text-gray-600 text-md font-semibold mb-2'>{house.address}</div>
        {/* <p className='text-gray-500 text-base'>{house.summary}</p> */}
        {/* <p className='text-gray-500 text-base'>{house.description.length <= 100 ? house.description : house.description.slice(0, 100) + ' . . .'}</p> */}
        <p className='text-gray-500 text-base'>{house.description}</p>

        <div className='flex gap-x-4 my-4 font-semibold'>
          {house.bedrooms > 0 && (
            <div className='flex items-center text-gray-600 gap-1'>
              <div className='text-[20px] rounded-full'>
                <BiBed />
              </div>
              <div className='text-base'>{house.bedrooms}</div>
            </div>
          )}

          {house.bathrooms > 0 && (
            <div className='flex items-center text-gray-600 gap-1'>
              <div className='text-[20px] rounded-full'>
                <BiBath />
              </div>
              <div className='text-base'>{house.bathrooms}</div>
            </div>
          )}

          {house.garage > 0 && (
            <div className='flex items-center text-gray-600 gap-1'>
              <div className='text-[20px] rounded-full'>
                <BiCar />
              </div>
              <div className='text-base'>{house.garage}</div>
            </div>
          )}

          {house.surface > 0 && (
            <div className='flex items-center text-gray-600 gap-1'>
              <div className='text-[20px] rounded-full'>
                <BiArea />
              </div>
              <div className='text-base'>{house.surface} <span className='text-sm'>m2</span></div>
            </div>
          )}
        </div>
        <div className='text-[1.2rem] font-semibold text-blue-600 mb-4'>
          R$ {formatCurrency(house.price, 0)}{house.option === 'Aluguel' && <span className='text-sm'>/mês</span>}
        </div>
        <div className='pb-2'>
          <PropertyFeatures house={house} />
        </div>
      </div>
    </div>
  );
};

export default House;
