import React, { useEffect, useState } from 'react';

import Image from '../assets/img/house-banner.png';
import Search from '../components/Search';

const Banner = () => {
  const [featuredTitle, setFeaturedTitle] = useState('Alugue');

  useEffect(() => {
    const dynamicTitle = Math.round(Math.random()) === 0 ? 'Alugue' : 'Compre';
    setFeaturedTitle(dynamicTitle);
  }, []);

  return (
    <section className='h-full mb-2'>
      <div className='flex flex-col lg:flex-row'>
        <div className='lg:hidden mb-6 mx-2'>
          <img className='rounded-[15px] max-h-[400px] w-full object-cover' src={Image} alt='Logo principal' />
        </div>

        <div className='lg:ml-8 xl:ml-[65px] flex flex-col items-center lg:items-start text-center lg:text-left justify-center flex-1 px-4 lg:px-0'>
          <h1 className='text-4xl lg:text-[58px] font-semibold leading-none mb-6 text-gray-700'>
            {featuredTitle === 'Alugue' ? (
              <span className='text-blue-600 tracking-[.29rem] mr-[0.73rem]'>{featuredTitle}</span>
            ) : (
              <span className='text-blue-600 mr-4'>{featuredTitle}</span>
            )}
            a casa dos seus sonhos com a gente.
          </h1>
          <p className='max-w-3xl lg:max-w-lg mb-8 text-md'>
            Atendemos nas cidades de Jacareí e São José dos Campos,
            região do Vale do Paraíba e te daremos todo suporte necessário na sua busca pelo imóvel perfeito, 
            trabalharemos adequando as possibilidades de imóveis à sua necessidade.

            {/* Acompanhe nossas novidades em apartamentos, casas e terrenos para vendas,
            permutas e locação. São ofertas incríveis e garantimos o&nbsp;
            <a className="sm:underline sm:decoration-blue-500 decoration-4">melhor atendimento!</a> */}
          </p>
        </div>
        <div className='hidden flex-1 lg:flex justify-end items-end 2xl:mr-[2rem]'>
          <img className='rounded-l-[15%] shadow hover:shadow-lg 2xl:rounded-r-[15%] max-w-[80%]' src={Image} alt='Logo principal' />
        </div>
      </div>
      <Search />
    </section>
  );
};

export default Banner;
