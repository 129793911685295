import React, { useEffect, useState } from 'react';

const _POLITICA_PRIVACIDADE = 'politica_privacidade';

const PrivacyPolicyPopup = () => {
  const [showPolicy, setShowPolicy] = useState(true);

  const handleClick = () => {
    const dateTime = new Date();
    setShowPolicy(false);
    localStorage.setItem(_POLITICA_PRIVACIDADE, dateTime);
  }

  useEffect(() => {
    const statusPolicy = localStorage.getItem(_POLITICA_PRIVACIDADE);
    if (statusPolicy && statusPolicy !== '' && Date.parse(statusPolicy)) {
      setShowPolicy(false);
    } else {
      setShowPolicy(true);
    }
  }, [showPolicy]);

  if (showPolicy) {
    return (
      <div className='fixed inset-x-0 bottom-0 z-50'>
        <div className='bg-indigo-600 px-4 py-3 text-white sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 opacity-90'>
          <div className='container flex-col my-0 mx-auto flex items-center gap-4 sm:flex-row'>
            <p className='text-center sm:text-left'>
              Usamos cookies para personalizar conteúdos e melhorar a sua experiência.&nbsp;
              <br className='sm:hidden' />
              {/* Ao navegar neste site, você concorda com a nossa &nbsp;<a className='underline' href='/politica-privacidade'>Política de Cookies</a>. */}
              Ao navegar neste site, você concorda com a nossa &nbsp;<a className='underline' href='/politica-privacidade'>Política de Privacidade</a>.
            </p>

            <button className='mt-4 block rounded-full w-full md:w-auto bg-white px-5 py-3 text-center text-sm font-medium text-indigo-600 transition hover:bg-white/90 focus:outline-none sm:mt-0'
              onClick={() => {
                handleClick();
              }}
            >
              Entendi
            </button>

          </div>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

export default PrivacyPolicyPopup;