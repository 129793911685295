import React from 'react';
import { FaFacebookSquare, FaInstagram, FaYoutube } from 'react-icons/fa';
import { MdEmail, MdPhone } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

import Image from '../assets/img/logo-bbs.png';

const Footer = () => {
  const location = useLocation();

  const goToHomePageOrNavigate = () => {
    const isMainPage = location.pathname === '/';

    if (isMainPage) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      window.location = '/';
    }
  }

  return (
    <footer className='text-center lg:text-left bg-gray-100 text-gray-600'>
      <div className='container max-w-[1440px] mx-auto'>
        <div className='flex justify-center items-center lg:justify-between px-8 py-6 border-b border-gray-200'>
          <div className='mr-12 hidden lg:block'>
            <span>Conecte-se conosco nas redes sociais:</span>
          </div>
          <div className='flex justify-center'>
            <a href='https://www.facebook.com/profile.php?id=100089483986318' target='_blank' rel='noreferrer' className='mr-6 text-gray-600'>
              <FaFacebookSquare size={24} />
            </a>
            <a href='https://www.instagram.com/bbs_imoveis' target='_blank' rel='noreferrer' className='mr-6 text-gray-600'>
              <FaInstagram size={24} />
            </a>
            {/* <a href='#!' className='mr-6 text-gray-600'>
              <FaTwitter size={24} />
            </a> */}
            <a href='https://youtube.com/@bbsimoveis' target='_blank' rel='noreferrer' className='mr-6 text-gray-600'>
              <FaYoutube size={24} />
            </a>
          </div>
        </div>
        <div className='mx-8 py-10 text-center md:text-left'>
          <div className='grid grid-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
            <div className=''>
                {/* <a href="/"> */}
                  <img className="mb-6 w-auto h-auto max-w-[120px] cursor-pointer" src={Image} alt="Logo da BBS Construtora e Imobiliária do rodapé" 
                    onClick={() => {
                      goToHomePageOrNavigate();
                    }}/>
                {/* </a> */}

              <p className='text-justify'>
                Visando construir a sua história no 
                mercado imobiliário na cidade de Jacareí e região do Vale do Paraíba, de forma, honesta, confiável e 
                duradoura. Nossos atendimentos são de forma exclusiva e personalizada, possuímos uma estrutura 
                jurídica segura e de profissionais totalmente qualificados com uma vasta experiência no mercado imobiliário.
              </p>
            </div>
            {/* <div className=''>
              <h6 className='uppercase font-semibold mb-4 flex justify-center md:justify-start'>
                Sobre
              </h6>
              <p className='mb-4'>
                <a href='#!' className='text-gray-600'>Link 1</a>
              </p>
              <p className='mb-4'>
                <a href='#!' className='text-gray-600'>Link 2</a>
              </p>
              <p className='mb-4'>
                <a href='#!' className='text-gray-600'>Link 3</a>
              </p>
              <p>
                <a href='#!' className='text-gray-600'>Link 4</a>
              </p>
            </div> */}
            <div className='flex md:justify-center'>
              <div className=''>
                <h6 className='uppercase font-semibold mb-4 flex justify-start'>
                  Links úteis
                </h6>
                <p className='mb-4 flex justify-start'>
                  <a href='https://www.itau.com.br/' target='_blank' rel='noreferrer' className='text-gray-600'>Itaú</a>
                </p>
                <p className='mb-4 flex justify-start'>
                  <a href='https://www.caixa.gov.br/Paginas/home-caixa.aspx' target='_blank' rel='noreferrer' className='text-gray-600'>Caixa</a>
                </p>
                <p className='mb-4 flex justify-start'>
                  <a href='https://www.santander.com.br/' target='_blank' rel='noreferrer' className='text-gray-600'>Santander</a>
                </p>
                <p className='mb-4 flex justify-start'>
                  <a href='https://www.bb.com.br/site/' target='_blank' rel='noreferrer' className='text-gray-600'>Banco do Brasil</a>
                </p>
                <p className='flex justify-start'>
                  <a href='https://banco.bradesco/html/classic/index.shtm' target='_blank' rel='noreferrer' className='text-gray-600'>Bradesco</a>
                </p>
              </div>
            </div>
            <div className=''>
              <h6 className='uppercase font-semibold mb-4 flex justify-start'>
                Contato
              </h6>
              <p className='flex items-center justify-start mb-4 gap-4'>
                <MdEmail size={24}/>
                contato@bbsimoveis.com.br
              </p>
              {/* <p className='flex items-center justify-start mb-4 gap-4'>
                <MdPhoneIphone size={24} />
                (12) 98262-2050
              </p> */}
              <p className='flex items-center justify-start gap-4'>
                <MdPhone size={24} />
                (12) 3354-2663
              </p>
              <br />
              {/* <p className='flex items-center justify-start gap-4'>
                <a href='/politica-privacidade'className='text-gray-600'>Política de privacidade</a>
              </p> */}
            </div>
          </div>
        </div>
      </div>

      <div className='text-center p-6 bg-gray-900 text-white'>
        <p className="text-xs text-gray-500">
          {/* &copy; 2023. BBS Imóveis - Bruno Bárbara da Silva - CRECI SP: 195315-F. */}
          &copy; 2023. BBS Construtora e Imobiliária LTDA &nbsp;|&nbsp; CRECI 42824-J &nbsp;|
          <a href='/politica-privacidade' className='text-gray-500'>&nbsp; Política de Privacidade</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
