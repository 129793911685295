import React from 'react';

const PropertyFeatures = ({ house }) => {
  return (
    <>
      {house.features.map((feature, index) => {
        return <span key={index} className='inline-block bg-gray-200 rounded-full shadow-sm px-3 text-sm font-semibold text-gray-700 mr-2 mb-2'>{feature}</span>
      })}
    </>
  )
}

export default PropertyFeatures;