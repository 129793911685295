import React, { createContext, useEffect, useState } from 'react';

import { GraphQLClient } from 'graphql-request';
import { ImoveisQuery } from '../api/imoveis-data';

export const HouseContext = createContext();

// const altura = window.screen.height;
const largura = window.screen.width;
const _PAGINATION = largura >= 1234 ? 12 : largura < 1024 ? 8 : 9;

function getPaginationData(housesAvailable) {
  const paginationData = [];

  let houseList = [];
  for (let i = 0; i < housesAvailable.length; i++) {
    houseList.push(housesAvailable[i]);

    if (houseList.length === _PAGINATION) {
      paginationData.push(houseList);
      houseList = [];
    }
  }

  if (houseList.length > 0) {
    paginationData.push(houseList);
    houseList = [];
  }

  return paginationData;
}

const graphcmsEndpoint = process.env.REACT_APP_GRAPHCMS_ENDPOINT || '';

const graphql = new GraphQLClient(
  graphcmsEndpoint,
);

export async function fetchImoveis() {
  const data = await graphql.request(ImoveisQuery);
  return data;
}

const HouseContextProvider = ({ children }) => {
  const [housesData, setHousesData] = useState([]);
  const [fetchApi, setFetchApi] = useState(false);

  const [data, setData] = useState([]);
  const [houses, setHouses] = useState([]);
  const [featuredHouses, setFeaturedHouses] = useState([]);

  const [paginationData, setPaginationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [country, setCountry] = useState('Todos');
  const [countries, setCountries] = useState([]);

  const [city, setCity] = useState('Todas');
  const [cities, setCities] = useState([]);

  const [neighborhood, setNeighborhood] = useState('Todos');
  const [neighborhoods, setNeighborhoods] = useState([]);

  const [property, setProperty] = useState('Todos');
  const [properties, setProperties] = useState([]);

  const [option, setOption] = useState('Todas');
  const [options, setOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const isDefault = (str) => {
    return str.split(' ').includes('Todos') || str.split(' ').includes('Todas');
  };

  const filters = {
    country: !isDefault(country) ? country : null,
    city: !isDefault(city) ? city : null,
    neighborhood: !isDefault(neighborhood) ? neighborhood : null,
    property: !isDefault(property) ? property : null,
    option: !isDefault(option) ? option : null
  }

  useEffect(() => {
    const allCountries = data.map((house) => {
      return house.country;
    });

    const uniqueCountries = ['Todos', ...new Set(allCountries)];
    setCountries(uniqueCountries);
  }, [data]);

  useEffect(() => {
    const allCities = data.map((house) => {
      return house.city;
    });

    const uniqueCities = ['Todas', ...new Set(allCities)];
    setCities(uniqueCities);
  }, [data]);

  useEffect(() => {
    setNeighborhood('Todos');

    const AllNeighborhoodsByCity = data.filter(house => house.city === city).map((house) => {
      if (house.neighborhood) {
        return house.neighborhood;
      }
    });

    const uniqueNeighborhood = ['Todos', ...new Set(AllNeighborhoodsByCity)];
    setNeighborhoods(uniqueNeighborhood);
  }, [city]);

  useEffect(() => {
    const allProperties = data.map((house) => {
      return house.property;
    });

    const uniqueProperties = ['Todos', ...new Set(allProperties)];
    setProperties(uniqueProperties);
  }, [data]);

  useEffect(() => {
    // let allOptions = [];
    // data.map((house) => {
    //   house.options.map((option) => {
    //     allOptions.push(option);
    //   });
    // });
    const allOptions = data.map((house) => {
      return house.option;
    });

    const uniqueOptions = ['Todas', ...new Set(allOptions)];
    setOptions(uniqueOptions);
  }, [data]);

  const filterHouses = (houses) => {
    let activeFilters = []
    for (const key in filters) {
      if (filters[key]) {
        activeFilters.push({
          key,
          value: filters[key]
        });
      }
    }

    let newHouses = houses;
    for (const filter of activeFilters) {
      newHouses = newHouses.filter((house) => {
        // if (filter.key === 'option') {
        //   return house['options'].includes(filter.value);
        // } else {
          return house[filter.key] === filter.value;
        // }
      });
    }

    newHouses.sort(function (a, b) {
      return a.price - b.price;
    });

    return newHouses;
  }

  const filterFeaturedHouses = (houses) => {
    let newHouses = houses;
    newHouses = newHouses.filter((house) => house.emDestaque === true);

    newHouses.sort(function (a, b) {
      return a.price - b.price;
    });

    return newHouses;
  }

  const handleClick = () => {
    setLoading(true);
    setFilterLoading(true);
    setFetchApi(false);

    const filteredHouses = filterHouses(housesData);
    setPaginationData(getPaginationData(filteredHouses));

    setTimeout(() => {
      return (
        filteredHouses.length < 1 ? setHouses([]) : setHouses(filteredHouses.slice(0, _PAGINATION)),
        setLoading(false),
        setFilterLoading(false),
        getPaginationData(filteredHouses)
      );
    }, 1500);
  };

  const handlePaginationClick = (pagina) => {
    setLoading(true);
    setCurrentPage(pagina);

    setTimeout(() => {
      setHouses(paginationData[pagina])
      setLoading(false);
    }, 1000);
  }

  if (!fetchApi) {
    const imoveis = fetchImoveis();
    imoveis.then(data => {
      data.imoveis.sort(function (a, b) {
        return a.price - b.price;
      });

      // remove a propriedade url das fotos do imóvel
      data.imoveis = data.imoveis.map(foto => ({
        ...foto,
        images: foto.images.map(foto => foto.url)
      }));

      setFetchApi(true);

      setData(data.imoveis);
      setHousesData(data.imoveis);

      const filteredFeaturedHouses = filterFeaturedHouses(data.imoveis);
      setFeaturedHouses(filteredFeaturedHouses);

      const filteredHouses = filterHouses(data.imoveis)
      setPaginationData(getPaginationData(filteredHouses));

      setHouses(data.imoveis.slice(0, _PAGINATION))
      setCurrentPage(0);
    })
  }

  return (
    <HouseContext.Provider
      value={{
        country,
        setCountry,
        countries,
        city,
        setCity,
        cities,
        neighborhood,
        setNeighborhood,
        neighborhoods,
        property,
        setProperty,
        properties,
        option,
        setOption,
        options,
        handleClick,
        houses,
        loading,
        filterLoading,
        paginationData,
        currentPage,
        handlePaginationClick,
        featuredHouses
      }}
    >
      {children}
    </HouseContext.Provider>
  );
};

export default HouseContextProvider;
