import React, { useContext } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { Link } from 'react-router-dom';
import House from './House';
import { HouseContext } from './HouseContext';
import Pagination from './Pagination';

const HouseList = () => {
  const { houses, loading } = useContext(HouseContext);

  if (!houses || houses?.length < 1) {
    return (
      <div className='text-center text-3xl text-gray-400 mt-24 mb-44'>
        Nenhum imóvel encontrado.
      </div>
    );
  }

  if (loading) {
    return (
      <div className='text-center text-3xl text-gray-400 mt-24 mb-44'>
        Filtrando imóveis ...
        <div className='fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-200 opacity-60 flex flex-col items-center justify-center'>
          <ImSpinner8 size={64} className='animate-spin text-blue-700 text-4xl mt-[200px]' />
        </div>
      </div>
    );
  }

  return (
    <section className='mb-14'>
      <div className='container mx-auto max-w-full 2xl:max-w-full'>
        {loading && (
          <div className='fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-200 opacity-60 flex flex-col items-center justify-center'>
            <ImSpinner8 size={64} className='animate-spin text-blue-700 text-4xl mt-[200px]' />
          </div>
        )}

        <div className='grid grid-cols-1 gap-8 md:px-4 md:grid-cols-2 lg:px-0 lg:grid-cols-3 xl:px-0 xl:grid-cols-4 xl:scale-95'>
          {houses.map((house, index) => {
            return (
              <Link className='lg:transform lg:transition lg:duration-500 lg:hover:-translate-y-2' to={`/imovel/${house.id}`} key={index}>
                <House house={house} />
              </Link>
            );
          })}
        </div>
        <Pagination />
      </div>
    </section>
  );
};

export default HouseList;
