import React, { useContext } from 'react';

import CityDropdown from './CityDropdown';
// import NeighborhoodDropdown from './NeighborhoodDropdown';
import NeighborhoodModal from './NeighborhoodModal';
import PropertyDropdown from './PropertyDropdown';

import { HouseContext } from './HouseContext';

import { BiSearchAlt } from 'react-icons/bi';
import OptionDropdown from './OptionDropdown';

const Search = () => {
  const { handleClick } = useContext(HouseContext);

  return (
    <div className='z-[10] lg:px-[30px] py-6 mx-4 md:mx-8 flex flex-col lg:flex-row justify-between gap-4 lg:gap-x-3 relative lg:-top-4 lg:shadow-md lg:bg-[#ffffffe6] rounded-full xl:scale-95'>
      <CityDropdown />
      {/* <NeighborhoodDropdown /> */}
      <NeighborhoodModal />
      <PropertyDropdown />
      <OptionDropdown />
      <button className='bg-blue-600 hover:bg-blue-700 transition w-full lg:max-w-[162px] h-16 rounded-full flex justify-center items-center text-white text-lg'
        onClick={() => {
          handleClick();
        }}
      >
        <BiSearchAlt size={24} />
        &nbsp; Filtrar
      </button>
    </div>
  );
};

export default Search;
