import React from 'react';

import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

const ImageSlideshow = ({ images, mode }) => {
  let containerStyle = 'rounded-2xl';
  let photoStyle = 'h-[234px] sm:h-[384px] md:h-[490px] lg:h-[511px]'
  let delay = 10000;

  if (mode === 'listing') {
    containerStyle = 'rounded-t-2xl';
    photoStyle = 'h-[210px]'
    delay = 15000;
  }

  return (
    <Swiper
      autoHeight={true}
      loop={false}
      spaceBetween={10}
      centeredSlides={true}
      autoplay={{
        delay: delay,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className={`mySwiper ${containerStyle} .swiper-button-prev .swiper-button-next .swiper-pagination-bullet-active`}
    >
      {images.map((item, index) => (
        <SwiperSlide key={index}>
          <img
            className={`object-cover w-full ${photoStyle}`}
            src={item}
            alt={'Foto ' + index}
            height={100}
            width={100}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ImageSlideshow;
