import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BasicPropertyInformation from './BasicPropertyInformation';
import { HouseContext } from './HouseContext';
import PropertyFeatures from './PropertyFeatures';

const FeaturedHouses = () => {
  const { featuredHouses } = useContext(HouseContext);
  const location = useLocation();

  const routesNotToDisplay = ['/imovel', '/destaque', '/politica-privacidade'];
  let showFeaturedHousesByRoute = true;

  routesNotToDisplay.forEach(route => {
    if (location.pathname.includes(route)) {
      showFeaturedHousesByRoute = false;
    }
  });

  if ((!featuredHouses || featuredHouses.length <= 0) || !showFeaturedHousesByRoute) {
    return (
      <></>
    )
  }

  return (
    <section className="bg-[#f0f0f0]">
      <div className="container max-w-[1440px] mx-auto px-8 py-6 sm:px-6 lg:px-8 m-auto">
        {/* <div className="grid grid-cols-1 gap-y-3 lg:grid-cols-3 lg:items-center lg:gap-x-16 my-4 mx-8 "> */}
        <div className="grid grid-cols-1 gap-y-3 lg:grid-cols-3 lg:items-center lg:gap-x-10 my-4 mx-4 md:mx-5">
          {/* <div className="max-w-xl text-center sm:text-left"> */}
          <div className="text-center sm:text-left">
            {/* <h2 className="text-3xl sm:text-4xl font-semibold leading-none mb-6 text-gray-700">
              Imóveis únicos e exclusivos,&nbsp;
              <br className="hidden sm:block lg:hidden" />
              nós temos as melhores opções!
            </h2>

            <p className="mt-4 text-gray-500">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas
              veritatis illo placeat harum porro optio fugit a culpa sunt id!
            </p> */}

            <h2 className="text-3xl sm:text-4xl font-semibold mb-6 text-gray-700">
              Imóveis exclusivos e&nbsp;
              {/* <br className="hidden sm:block lg:hidden" /> */}
              de qualidade
            </h2>

            <p className="mt-4 text-gray-500">
              Encontre as melhores oportunidades e exclusividades de imóveis que estão
              em destaque para nossos clientes. {/*Temos a qualidade e confiabilidade para
              atender especificamente a sua necessidade.*/}
            </p>

            <div className="hidden lg:mt-8 lg:flex lg:gap-4">
              <button className="prev-button rounded-full border border-blue-600 p-3 text-blue-600 hover:bg-blue-600 hover:text-white">
                <span className="sr-only">Previous Slide</span>
                <svg
                  className="h-5 w-5 -rotate-180 transform"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </button>

              <button className="next-button rounded-full border border-blue-600 p-3 text-blue-600 hover:bg-blue-600 hover:text-white">
                <span className="sr-only">Next Slide</span>
                <svg
                  className="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="-mx-6 lg:col-span-2 lg:mx-0">
            <Swiper
              slidesPerView={1}
              grabCursor={true}

              autoHeight={true}
              loop={true}
              spaceBetween={10}
              centeredSlides={true}
              autoplay={{
                delay: 800000,
                disableOnInteraction: false,
              }}
              pagination={{
                enabled: false,
                clickable: true,
              }}
              // navigation={true}
              navigation={{
                nextEl: '.next-button',
                prevEl: '.prev-button',
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className={`mySwiper .swiper-pagination-bullet-active`}
            >
              {featuredHouses.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper-slide">
                    {/* <blockquote className="flex h-full flex-col justify-between bg-white p-6 m-3 lg:m-10 shadow-1 rounded-[15px] transition hover:shadow-xl"> */}
                    {/* <blockquote className="flex h-full flex-col justify-between bg-white m-3 lg:m-10 shadow-1 rounded-[15px] transition hover:shadow-xl lg:mr-0"> */}
                    <blockquote className="flex h-full flex-col justify-between bg-white m-3 lg:m-12 shadow-lg rounded-[15px] transition hover:shadow-xl">
                      <div>
                        <article className="flex flex-col md:flex-row bg-white rounded-full md:h-auto">
                        {/* <article className="flex flex-col md:flex-row bg-white rounded-full h-[45rem] md:h-auto"> */}
                          {/* <div className="rotate-180 p-2 [writing-mode:_vertical-lr]">
                            <time
                              dateTime="2022-10-10"
                              className="flex items-center justify-between gap-4 text-xs font-bold uppercase text-gray-900"
                            >
                              <span>2022</span>
                              <span className="w-px flex-1 bg-gray-900/10"></span>
                              <span>Oct 10</span>
                            </time>
                          </div> */}

                          {/* <div className="hidden sm:block sm:basis-56"> */}
                          {/* <div className="block basis-56"> */}
                          <div className="block basis-[40%]">
                            <img
                              alt={item.id}
                              src={item.images[0]}
                              className="aspect-square h-full w-full object-cover rounded-tl-[15px] rounded-tr-[15px] md:rounded-bl-[15px] md:rounded-tr-[0]"
                            />
                          </div>

                          <div className="flex flex-1 flex-col justify-between pl-6 pt-4 pr-6 pb-6 lg:pt-7 lg:pr-7 lg:pb-7 lg:h-[380px] xl:h-auto">
                          {/* <div className="flex flex-1 flex-col justify-between pl-6 pt-4 pr-6 pb-6 lg:pl-2 lg:pt-7 lg:pr-7 lg:pb-7 min-h-[288px]"> */}
                          {/* <div className="flex flex-1 flex-col justify-between"> */}
                            {/* <div className="border-l border-gray-900/10 p-4 sm:border-l-transparent sm:p-6"> */}
                            {/* <div className="mt-4 lg:mt-0 lg:ml-4"> */}
                            <div className="lg:ml-4 contents">
                              <div>
                                <div className='flex justify-between'>
                                  <h3 className="font-bold text-md uppercase text-gray-700 lg:mt-0 self-center">
                                    {/* Descrição do imóvel - {item.property} */}
                                    {item.property} em {item.city} - {item.state}
                                  </h3>
                                  {/* <Link className=' hidden xl:block bg-blue-600 px-5 py-2 text-center text-sm font-normal text-white transition hover:bg-blue-700 rounded-full' to={`/imovel/${item.id}/destaque`}>
                                    Saiba mais
                                  </Link> */}
                                </div>

                                <p className="mt-2 text-sm leading-relaxed text-gray-700 line-clamp-3">
                                  {item.description}
                                  {/* {item.description.length <= 100 ? item.description : item.description.slice(0, 120) + ' . . .'} */}
                                </p>

                                <div className='pb-4'>
                                  <BasicPropertyInformation house={item} />
                                  <PropertyFeatures house={item} />
                                </div>
                              </div>

                              <div className="sm:flex sm:items-end sm:justify-end ">
                                {/* <Link className='block xl:hidden bg-blue-600 px-5 py-3 lg:py-1 text-center text-md font-normal text-white transition hover:bg-blue-700 rounded-full' to={`/imovel/${item.id}/destaque`}> */}
                                <Link className='block bg-blue-600 px-5 py-3 lg:py-1 text-center text-md font-normal text-white transition hover:bg-blue-700 rounded-full' to={`/imovel/${item.id}/destaque`}>
                                  Saiba mais
                                </Link>
                              </div>
                            </div>

                            {/* <div className="sm:flex sm:items-end sm:justify-end mt-6">
                              <Link className='block bg-blue-600 px-5 py-3 text-center text-md font-normal text-white transition hover:bg-blue-700 rounded-full' to={`/imovel/${item.id}/destaque`}>
                                Saiba mais
                              </Link>
                            </div> */}

                            {/* <div className="sm:flex sm:items-end sm:justify-end">
                              <Link className='block bg-blue-600 px-5 py-3 text-center text-md font-normal text-white transition hover:bg-blue-700 rounded-full' to={`/imovel/${item.id}/destaque`}>
                                Saiba mais
                              </Link>
                            </div> */}
                          </div>
                        </article>
                      </div>

                      {/* <footer className="mt-8 text-sm text-gray-500">
                        &mdash; Michael Scott
                      </footer> */}
                    </blockquote>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className="mt-8 flex justify-center gap-4 lg:hidden">
          <button
            aria-label="Previous slide"
            className="prev-button rounded-full border border-blue-600 p-4 text-blue-600 hover:bg-blue-600 hover:text-white"
          >
            <svg
              className="h-5 w-5 -rotate-180 transform"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5l7 7-7 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>

          <button
            aria-label="Next slide"
            className="next-button rounded-full border border-blue-600 p-4 text-blue-600 hover:bg-blue-600 hover:text-white"
          >
            <svg
              className="h-5 w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5l7 7-7 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  )
}

export default FeaturedHouses;
