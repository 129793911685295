import React from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa';
import { IoResize } from 'react-icons/io5';
import { MdAttachMoney } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

const _LINK_WHATSAPP = 'https://wa.me/551233542663?text=';
const _SERVICO_IMOVEL_SOB_ENCOMENDA = _LINK_WHATSAPP + 'Gostaria%20de%20saber%20informações%20sobre%20imóveis%20sob%20encomenda.%20Pode%20me%20ajudar?';
const _SERVICO_FINANCIAMENTO = _LINK_WHATSAPP + 'Gostaria%20de%20saber%20informações%20sobre%20as%20ofertas%20de%20financiamento.%20Pode%20me%20ajudar?';
const _SERVICO_ANUNCIAR_IMOVEL = _LINK_WHATSAPP + 'Gostaria%20de%20anunciar%20meu%20imóvel%20com%20a%20BBS%20Construtora%20e%20Imobiliária.%20Podemos%20conversar?';

const Services = () => {
  const location = useLocation();

  const routesNotToDisplay = ['/politica-privacidade'];
  let showServicesByRoute = true;

  routesNotToDisplay.forEach(route => {
    if (location.pathname.includes(route)) {
      showServicesByRoute = false;
    }
  });

  if (!showServicesByRoute) {
    return (
      <></>
    )
  }

  return (
    <section className="bg-gray-900 text-white">
      <div className="container max-w-[1440px] mx-auto px-8 py-6 sm:px-6 lg:px-8 m-auto">
        <div className="max-w-[48rem] my-12 md:my-10 mx-auto">
          <h2 className="text-3xl font-semibold sm:text-4xl text-center">Temos o melhor negócio para você</h2>

          {/* <p className="mt-4 text-gray-300">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
            dolores iure fugit totam iste obcaecati. Consequatur ipsa quod ipsum
            sequi culpa delectus, cumque id tenetur quibusdam, quos fuga minima.
          </p> */}
        </div>

        <div className="mt-8 mb-8 grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-12 lg:grid-cols-3 mx-8">
          <div className="flex items-start">
            <span className="flex-shrink-0 rounded-lg bg-gray-800 p-4">
              <IoResize size={32} />
            </span>
            <div className="ml-4">
              <h2 className="text-lg font-bold">Imóvel sob encomenda</h2>

              <p className="mt-1 text-sm text-gray-300">
                Descreva o imóvel que você procura e nós avisaremos quando encontrá-lo.
              </p>
              <a href={_SERVICO_IMOVEL_SOB_ENCOMENDA} target="_blank" rel="noreferrer" className="group mt-4 inline-flex items-center gap-1 text-sm font-medium text-blue-500">
                Saiba mais
                <span aria-hidden="true" className="block transition group-hover:translate-x-0.5 text-lg">
                  {/* &rarr; */}
                  <FaArrowRight size={16} />
                </span>
              </a>
            </div>
          </div>

          <div className="flex items-start">
            <span className="flex-shrink-0 rounded-lg bg-gray-800 p-4">
              <MdAttachMoney size={32} />
            </span>

            <div className="ml-4">
              <h2 className="text-lg font-bold">Financiamento</h2>

              <p className="mt-1 text-sm text-gray-300">
                As melhores ofertas de crédito para você financiar seu imóvel.
              </p>
              <a href={_SERVICO_FINANCIAMENTO} target="_blank" rel="noreferrer" className="group mt-4 inline-flex items-center gap-1 text-sm font-medium text-blue-500">
                Saiba mais
                <span aria-hidden="true" className="block transition group-hover:translate-x-0.5 text-lg">
                  {/* &rarr; */}
                  <FaArrowRight size={16} />
                </span>
              </a>
            </div>
          </div>

          <div className="flex items-start">
            <span className="flex-shrink-0 rounded-lg bg-gray-800 p-4">
              <AiOutlineHome size={32} />
            </span>

            <div className="ml-4">
              <h2 className="text-lg font-bold">Cadastre seu imóvel</h2>

              <p className="mt-1 text-sm text-gray-300">
                Anuncie conosco! Nós encontraremos o melhor negócio para você.
              </p>
              <a href={_SERVICO_ANUNCIAR_IMOVEL} target="_blank" rel="noreferrer" className="group mt-4 inline-flex items-center gap-1 text-sm font-medium text-blue-500">
                Saiba mais
                <span aria-hidden="true" className="block transition group-hover:translate-x-0.5 text-lg">
                  {/* &rarr; */}
                  <FaArrowRight size={16} />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services;
