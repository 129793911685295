import React from 'react';

import Banner from '../components/Banner';
import HouseList from '../components/HouseList';

const Home = () => {
  return (
    <>
      <Banner />
      <HouseList />
    </>
  );
};

export default Home;
