import { BiError } from 'react-icons/bi';
import { FiArrowLeft } from 'react-icons/fi';

const PropertyNotFound = (props) => {
  return (
    <section className='h-full my-16'>
      <div className='flex flex-col lg:flex-row'>
        <div className='container mx-auto max-w-full 2xl:max-w-full'>
          <div className="rounded-[90px] flex items-center justify-center">
            <div className="rounded-[90px] px-10 py-20 bg-gray-100 shadow-2 bg-gradient-to-b from-gray-100 to-gray-50">
              <div className="flex flex-col items-center">
                <h1 className="text-4xl lg:text-[58px] font-semibold leading-none mb-6 text-gray-700">
                  <BiError color='#eab309' size={64} />
                </h1>

                <h6 className="mb-2 text-2xl font-bold text-center text-gray-600 md:text-3xl">
                  Não encontramos nenhum imóvel com o código <span className="text-blue-500">{props?.id}</span>
                </h6>

                <p className="mb-8 text-center text-gray-500 md:text-lg">
                  Este imóvel não está mais disponível ou não existe.
                </p>

                <a href="/" className="flex items-center px-6 py-2 mt-3 sm:mt-0 w-full max-w-[150px] gap-2 text-center text-white bg-green-2 hover:bg-green-3 rounded-full lg:ml-5">
                  <FiArrowLeft size={24} />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PropertyNotFound;
