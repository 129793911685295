import React, { useContext, useEffect, useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { useLocation, useParams } from 'react-router-dom';
import { formatCurrency } from '../utils/formatCurrency';

import { HouseContext } from '../components/HouseContext';
import ImageSlideshow from '../components/ImageSlideshow';

import UserImg from '../assets/img/agents/user1.png';
import BasicPropertyInformation from '../components/BasicPropertyInformation';
import PropertyFeatures from '../components/PropertyFeatures';
import PropertyNotFound from '../components/PropertyNotFound';

const _LINK_WHATSAPP = 'https://wa.me/551233542663?text=';

const PropertyDetails = () => {
  const { houses, featuredHouses } = useContext(HouseContext);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const location = useLocation();
  const isFeaturedHouse = location.pathname.includes('/destaque');

  const property = isFeaturedHouse ? 
    featuredHouses.find((house) => house.id === id.toUpperCase()) : houses.find((house) => house.id === id.toUpperCase());

  const auxiliaryText = property?.property.toLowerCase() === 'casa' ? 'na' : 'no';
  // const currentProperty = `Olá, tenho interesse ${auxiliaryText} ${property?.property.toLowerCase()} ${property?.id}.`;
  const currentProperty = `Olá,%20tenho%20interesse%20${auxiliaryText}%20${property?.property.toLowerCase()}%20${property?.id}.%20Podemos%20conversar?`;

  function scrollTop() {
    window.scrollTo({
      top: (window.innerWidth < 768) ? 100 : 0,
      behavior: 'smooth'
    });
  }

  function hiddenLoading() {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  useEffect(() => {
    scrollTop();
    hiddenLoading();
  }, []);

  if (!property) {
    return (
      <>
        {loading && (
          <div className='fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-200 opacity-60 flex flex-col items-center justify-center'>
            <ImSpinner8 size={64} className='animate-spin text-blue-700 text-4xl mt-[200px]' />
          </div>
        )}
        <PropertyNotFound id={id}/>
      </>
    );
  };

  return (
    <div className='container mx-auto min-h-[800px] mb-14'>
      {loading && (
        <div className='fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-200 opacity-60 flex flex-col items-center justify-center'>
          <ImSpinner8 size={64} className='animate-spin text-blue-700 text-4xl mt-[200px]' />
        </div>
      )}

      <div className='flex flex-col xl:flex-row xl:items-center xl:justify-between'>
        <div>
          <div className='flex items-center gap-2'>
            <h2 className='text-2xl font-semibold text-gray-700'>{property?.property} {property?.id}</h2>
            <span className='inline-block bg-orange-500 opacity-80 rounded-full shadow-md px-3 text-sm font-normal text-white ml-4 mb-0'>
              {/* {property?.options.join(' / ')} */}
              {property?.option}
            </span>
          </div>

          <h3 className='text-lg mb-4 text-gray-700'>{property?.address} - {property?.city}/{property?.state}</h3>
        </div>
        <div className='text-3xl font-semibold text-blue-600 mb-4 xl:mb-0'>
          R$ {formatCurrency(property?.price, 0)}{property?.option === 'Aluguel' && <span className='text-sm'>/mês</span>}
        </div>
      </div>

      <div className='flex flex-col items-start gap-8 xl:flex-row'>
        <div className='max-w-[100%] xl:max-w-[768px]'>
          <div className='mb-8'>
            {property?.images?.length > 0 ? (
              <ImageSlideshow
                images={property?.images}
                altText={'Foto'}
              />
            ) : (
              <img src={property?.imageLg} alt='Foto do imóvel' />
            )}
          </div>

          <BasicPropertyInformation house={property}/>

          <div className='mb-6'>
            <p className='text-gray-600 text-justify mb-4'>{property?.description}</p>

            {property?.details.map((detail, index) => {
              return <p key={index} className='text-gray-600 text-justify mb-4'>{detail}</p>
            })}
          </div>

          {property?.features.length > 0 && (
            <div>
              <PropertyFeatures house={property} />
            </div>
          )}
        </div>

        <div className='flex-1 w-full mb-8 bg-white shadow-header rounded-[15px] px-6 py-8'>
          {/* <h2 className='text-xl mb-1 font-semibold text-gray-700'>Interessado no imóvel?</h2> */}
          <h2 className='text-xl mb-4 font-semibold text-gray-700'>{`Interessado ${auxiliaryText} ${property?.property.toLowerCase()}?`}</h2>
          <p className='text-md mb-4 text-gray-500'>Para mais informações fale com o corretor responsável pelo anúncio.</p>

          {property?.options?.length > 0 && (
            <>
              <div className='text-md mb-4 text-gray-500 flex items-center'>
                {/* <BsInfoCircleFill className='mr-2' /> {property.property} também disponível para <span className='ml-1 font-semibold'>{property.options.join(' / ')}</span>. */}
                <p>{property.property} também disponível para<span className='ml-1 font-semibold'>{property.options.join(' / ')}</span>.</p>
              </div>
            </>
          )}

          <div className='flex items-center gap-x-4 mb-4'>
            {/* <div className='w-20 h-20 p-1 border border-gray-300 rounded-full'> */}
            <div className='w-20 h-20 p-1 rounded-full'>
              <img className='opacity-60' src={UserImg} alt="Foto do corretor"></img>
            </div>
            <div>
              <p className='font-semibold text-gray-700 text-lg'>Bruno Bárbara</p>
              <p className='text-gray-600 text-sm'>CRECI SP: 195315-F</p>
              <p className='text-gray-600 text-sm'>(12) 3354-2663</p>
            </div>
          </div>
          <div className='flex gap-x-2'>
            <a href={_LINK_WHATSAPP + currentProperty} target="_blank" rel="noreferrer"
              className='bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 text-sm w-full transition text-center'
              type='submit'
            >
              Contato
            </a>
            {/* <button className='border border-blue-700 text-blue-700 hover:border-blue-600 hover:text-blue-600 rounded-full p-4 text-sm w-full transition sm:hidden'>
              Ligar
            </button> */}
          </div>
        </div>

        {/* <div className='flex-1 w-full mb-8 bg-white border border-gray-300 rounded-[15px] px-6 py-8'>
          <div className='flex items-center gap-x-4 mb-8'>
            <div className='w-20 h-20 p-1 border border-gray-300 rounded-full'>
              <img className='opacity-60' src={UserImg} alt="Foto do corretor"></img>
            </div>
            <div>
              <div className='font-bold text-gray-700 text-lg'>Bruno Bárbara</div>
              <div className='font-semibold text-gray-500 text-sm'>(12) 98262-2050</div>
            </div>
          </div>
          <form className='flex flex-col gap-y-4'>
            <input
              className='border border-gray-300 focus:border-blue-700 rounded w-full px-4 h-14 text-sm outline-none'
              type='text'
              placeholder='* Nome completo'
            />
            <input
              className='border border-gray-300 focus:border-blue-700 rounded w-full px-4 h-14 text-sm outline-none'
              type='text'
              placeholder='* Email'
            />
            <input
              className='border border-gray-300 focus:border-blue-700 rounded w-full px-4 h-14 text-sm outline-none'
              type='text'
              placeholder='* Whatsapp'
            />
            <textarea
              className='border border-gray-300 focus:border-blue-700 rounded w-full p-4 h-36 text-sm text-gray-400 outline-none resize-none'
              type='text'
              placeholder='* Mensagem'
              defaultValue={currentProperty}
            />
            <div className='flex gap-x-2'>
              <button
                className='bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 text-sm w-full transition'
                type='submit'
              >
                Enviar
              </button>
              <button className='border border-blue-700 text-blue-700 hover:border-blue-600 hover:text-blue-600 rounded-full p-4 text-sm w-full transition sm:hidden'>
                Ligar
              </button>
            </div>
          </form>
        </div> */}
      </div>
    </div>
  );
};

export default PropertyDetails;
