import { Disclosure } from '@headlessui/react';
import { RiWhatsappLine } from 'react-icons/ri';
import Image from '../assets/img/logo-bbs.png';

const _LINK_WHATSAPP = 'https://wa.me/551233542663';

export default function Header() {
  const navigation = [/* links menu*/];

  return (
    <header className="w-full mb-6 shadow-header">
      <nav className="container max-w-[1440px] mx-auto flex flex-wrap items-center justify-between py-5">
        {/* Logo  */}
        <Disclosure>
          {({ open }) => (
            <>
              <div className="flex flex-wrap items-center justify-between w-full lg:w-auto">
                <a href="/" className="flex items-center space-x-2 text-2xl font-medium text-blue-500">
                  <span>
                    <img className="w-auto h-auto max-w-[120px] ml-3 lg:ml-0" src={Image} alt="Logo da BBS Construtora e Imobiliária" />
                  </span>
                </a>

                <Disclosure.Button
                  aria-label="Toggle Menu"
                  className="px-2 py-1 ml-auto text-gray-500 rounded-full lg:hidden hover:text-blue-500 focus:text-blue-500 focus:bg-blue-100 focus:outline-none">

                  {/* Se não existem opções no menu exibe somente o botão de contato */}
                  {navigation.length === 0 && (
                    <a href={_LINK_WHATSAPP} target="_blank" rel="noreferrer" className="flex items-center px-6 py-2 mt-3 sm:mt-0 w-full max-w-[150px] gap-2 text-center text-white bg-green-2 hover:bg-green-3 rounded-full lg:ml-5">
                      <RiWhatsappLine size={24} />
                      Contato
                    </a>
                  )}

                  {/* Se existem opções no menu exibe os ícones de acordo com o status */}
                  {navigation.length > 0 && (
                    <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      {open && (
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                      )}
                      {!open && (
                        <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                      )}
                    </svg>
                  )}
                </Disclosure.Button>

                {navigation.length > 0 && (
                  <Disclosure.Panel className="flex flex-wrap w-full my-5 sm:flex-nowrap lg:hidden">
                    <>
                      {navigation.map((item, index) => (
                        <a key={index} href="#" className="flex sm:justify-center items-center w-full px-4 py-2 -ml-4 text-gray-900 rounded-md hover:text-blue-500 focus:text-blue-500">
                          {item}
                        </a>
                      ))}
                      <a href={_LINK_WHATSAPP} target="_blank" rel="noreferrer" className="flex items-center px-6 py-2 mt-3 sm:mt-0 w-full max-w-[150px] gap-2 text-center text-white bg-green-2 hover:bg-green-3 rounded-full lg:ml-5">
                        <RiWhatsappLine size={24} />
                        Contato
                      </a>
                    </>
                  </Disclosure.Panel>
                )}
              </div>
            </>
          )}
        </Disclosure>

        {/* menu  */}
        <div className="flex items-center">
          <div className="hidden text-center lg:flex lg:items-center">
            <ul className="items-center justify-end flex-1 pt-6 list-none lg:pt-0 lg:flex">
              {navigation.map((menu, index) => (
                <li className="mr-3 nav__item" key={index}>
                  <a href="#" className="inline-block px-4 py-2 text-md font-normal text-gray-800 no-underline rounded-md hover:text-blue-500 focus:text-blue-500">
                    {menu}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="hidden space-x-3 lg:flex nav__item">
            <a href={_LINK_WHATSAPP} target="_blank" rel="noreferrer" className="flex items-center px-6 py-2 w-full max-w-[150px] gap-2 text-center text-white bg-green-2 hover:bg-green-3 rounded-full lg:ml-5">
              <RiWhatsappLine size={24} />
              Contato
            </a>
          </div>
        </div>
      </nav>
    </header>
  )
}
