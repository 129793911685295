import React, { useContext, useState } from 'react';
import { HouseContext } from './HouseContext';

// import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';

const Pagination = () => {
  const { loading, filterLoading, paginationData, currentPage, handlePaginationClick } = useContext(HouseContext);
  const [page, setPage] = useState(currentPage);

  const scrollTop = () => {
    const largura = window.screen.width;
    // const _SCROLL = largura >= 1440 ? 670 : largura < 1024 ? 590 : 625;
    let _SCROLL = 680;

    if (largura <= 415) {
      _SCROLL = 1150;
    } else if (largura <= 768) {
      _SCROLL = 1000;
    } else if (largura <= 1024) {
      _SCROLL = 540;
    } else if (largura <= 1234) {
      _SCROLL = 620;
    }

    setTimeout(() => {
      window.scrollTo({
        top: _SCROLL,
        behavior: 'smooth'
      });
    }, 0);
  }

  const goToPreviousPage = () => {
    if (page === 0) {
      return;
    }

    const previousPage = page - 1;
    scrollTop();
    setPage(previousPage);
    handlePaginationClick(previousPage);
  }

  const goToNextPage = () => {
    if (page === paginationData.length - 1) {
      return;
    }

    const nextPage = page + 1;
    scrollTop();
    setPage(nextPage);
    handlePaginationClick(nextPage);
  }

  if (filterLoading && page > 0) {
    setPage(0);
  }

  return (
    <div className='my-6 mx-auto flex items-center justify-center'>
      {!loading && (
        <div className='flex justify-center items-center space-x-4'>
          {/* <div className={`px-2 py-1 text-3xl leading-6 ${page === 0 ? 'text-gray-300' : 'text-blue-600 transition hover:text-blue-700 cursor-pointer'}`}>
            <IoIosArrowDropleftCircle size={48}
              onClick={goToPreviousPage}
            />
          </div> */}
          <button className={`rounded-full border border-blue-600 p-3 text-blue-600 ${page === 0 ? 'hover:cursor-auto': 'hover:bg-blue-600 hover:text-white'}`} onClick={goToPreviousPage}>
            <svg
              className="h-5 w-5 -rotate-180 transform"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5l7 7-7 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>          
          <div className='text-slate-500'>{page + 1} / {paginationData.length ?? 1}</div>
          {/* <div className={`px-2 py-1 text-3xl leading-6 ${page + 1 === paginationData.length ? 'text-gray-300' : 'text-blue-600 transition hover:text-blue-700 cursor-pointer'}`}>
            <IoIosArrowDroprightCircle size={48}
              onClick={goToNextPage}
            />
          </div> */}
          <button className={`rounded-full border border-blue-600 p-3 text-blue-600 ${page + 1 === paginationData.length  ? 'hover:cursor-auto': 'hover:bg-blue-600 hover:text-white'}`} onClick={goToNextPage}>
            <svg
              className="h-5 w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 5l7 7-7 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default Pagination;
