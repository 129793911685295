const PrivacyPolicyTopicDetail = (props) => {
  const { isOpen, title, children } = props;

  return (
    <details className="group [&_summary::-webkit-details-marker]:hidden" open={isOpen}>
      <summary className="flex items-center justify-between p-4 rounded-lg cursor-pointer bg-[#f0f0f0]">
        <h2 className="font-bold text-gray-600">
          {title}
        </h2>

        <svg
          className="ml-1.5 h-5 w-5 flex-shrink-0 transition duration-300 group-open:-rotate-180 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </summary>

      <div className="px-4 mt-4 leading-relaxed text-gray-500">
        {children}
      </div>
    </details>
  )
}

export default PrivacyPolicyTopicDetail;
