import React from 'react';

import { BiArea, BiBath, BiBed, BiCar } from "react-icons/bi";

const BasicPropertyInformation = ({ house }) => {
  return (
    <div className='flex gap-x-4 my-4 font-semibold'>
      {house.bedrooms > 0 && (
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px] rounded-full'>
            <BiBed />
          </div>
          <div className='text-base'>{house.bedrooms}</div>
        </div>
      )}

      {house.bathrooms > 0 && (
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px] rounded-full'>
            <BiBath />
          </div>
          <div className='text-base'>{house.bathrooms}</div>
        </div>
      )}

      {house.garage > 0 && (
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px] rounded-full'>
            <BiCar />
          </div>
          <div className='text-base'>{house.garage}</div>
        </div>
      )}

      {house.surface > 0 && (
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px] rounded-full'>
            <BiArea />
          </div>
          <div className='text-base'>{house.surface} <span className='text-sm'>m2</span></div>
        </div>
      )}
    </div>
  )
}

export default BasicPropertyInformation;