import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext, useState } from 'react';
import { RiDatabaseLine } from 'react-icons/ri';

import { HouseContext } from './HouseContext';

export default function NeighborhoodModal({ disable }) {
  const { neighborhood, setNeighborhood, neighborhoods, city } = useContext(HouseContext);
  const [isOpen, setIsOpen] = useState(false);

  function hasSelectedCity() {
    return (city === 'Todas');
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    if (hasSelectedCity()) {
      return;
    }
    setIsOpen(true);
  }

  function setNeighborhoodAndCloseModal(selectedNeighborhood) {
    setNeighborhood(selectedNeighborhood);
    closeModal();
  }

  return (
    <>
      <div>
        <button
          type="button"
          onClick={openModal}
          className={`${hasSelectedCity() ? 'bg-[#f0f0f0] cursor-default' : ''} text-black transition w-full min-w-[276px] lg:max-w-[162px] h-16 rounded-full flex justify-center items-centertext-lg`}
        >
          <div className='flex items-center w-full p-[12px] rounded-full border border-gray-200 max-h-16'>
            <RiDatabaseLine className='dropdown-icon-primary ml-[6px]' />
            <div>
              <div className='text-[13px] text-left'>Bairro</div>
              <div className='text-[15px] text-left font-medium leading-tight'>{neighborhood}</div>
            </div>
          </div>
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-20"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-700"
                >
                  <div className='flex'>
                    <RiDatabaseLine className='dropdown-icon-primary ml-[6px]' />
                    Selecione o bairro
                  </div>
                </Dialog.Title>
                <div className="mt-2 border-t pt-2">
                  {neighborhoods.map((neighborhood, index) => {
                    return (
                      <div
                        onClick={() => setNeighborhoodAndCloseModal(neighborhood)}
                        key={index}
                        className='cursor-pointer hover:text-blue-600 transition mb-2'
                      >
                        {neighborhood}
                      </div>
                    );
                  })}
                </div>

                {/* <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm text-gray-900 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 duration-300"
                    onClick={closeModal}
                  >
                    Cancelar
                  </button>
                </div> */}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
