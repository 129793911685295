import { gql } from 'graphql-request';

export const ImoveisQuery = gql`{
  imoveis(where: {}, first: 1000) {
    id: codigo
    property: tipo
    option: opcao
    options: outrasOpcoes
    description: descricao
    details: detalhes
    images: fotos {
      url(transformation: {image: {resize: {height: 768, width: 500}}})
    }
    address: endereco
    neighborhood: bairro
    city: cidade
    state: estado 
    country: pais
    price: valor
    bedrooms: quartos
    bathrooms: banheiros
    garage: garagem
    surface: m2
    features: recursos
    emDestaque
  }
}`;
