import React from 'react';

import { Route, Routes } from 'react-router-dom';
import Container from './components/Container';
import Footer from './components/Footer';
import Header from './components/Header';
import FeaturedHouses from './components/FeaturedHouses';
import PageNotFound from './components/PageNotFound';
import PopupWidget from './components/PopupWidget';
import PrivacyPolicyPopup from './components/PrivacyPolicy/PrivacyPolicyPopup';
import Services from './components/Services';

import Home from './pages/Home';
import PropertyDetails from './pages/PropertyDetails';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

const App = () => {
  return (
    <>
      <Header />
      <Container>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/imovel/:id' element={<PropertyDetails />} />
          <Route path='/imovel/:id/destaque' element={<PropertyDetails />} />
          <Route path='/politica-privacidade' element={<PrivacyPolicy />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Container>
      <FeaturedHouses />
      <Services />
      <Footer />
      <PopupWidget />
      <PrivacyPolicyPopup />
    </>
  );
};

export default App;
